export default (urlPath: MaybeRefOrGetter<string>): Ref<string> => {
  const runtimeConfig = useRuntimeConfig();

  return computed(() => {
    const baseUrl = runtimeConfig.app.baseURL;
    const urlPathValue = toValue(urlPath);

    if (baseUrl.endsWith('/') && urlPathValue.startsWith('/')) {
      // if baseUrl ends with a slash and urlPathValue starts with a slash, remove one of them
      return `${baseUrl}${urlPathValue.slice(1)}`;
    }

    return `${baseUrl}${urlPathValue}`;
  });
};
