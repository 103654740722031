<template>
  <div class="item">
    <InputFake
      ref="fakeInput"
      :label="t('components.pageheader.search.item.item-date.input-fake.label')"
      :placeholder="placeholder"
      icon="ion:calendar-outline"
      :value="value"
      @click="emit('toggle', itemId)"
    />

    <PageheaderSearchItemTab
      ref="tab"
      :content="{ icon: 'ion:calendar-outline', text: 'Kalender' }"
      :state="isDateFilterSet ? 'ion:checkmark' : null"
      :active="showDropdown"
      @click="handleClick"
    />

    <PageheaderSearchItemDropdown
      v-if="showDropdown"
      v-focus-first
      v-click-outside="{
        ignore: [fakeInput, tab],
        handler: () => emit('deactivate', itemId),
      }"
      @keyup.esc="emit('deactivate', itemId)"
    >
      <InputCalendar v-model="model" @apply="emit('apply', itemId)" />
    </PageheaderSearchItemDropdown>
  </div>
</template>

<script lang="ts" setup>
import type { DateFilter } from '../models';
const { t, d } = useI18n();
const searchStore = useSearchStore();

const fakeInput = ref<HTMLElement | null>(null);
const tab = ref<HTMLElement | null>(null);

const model = defineModel<DateFilter>();

const emit = defineEmits([
  'activate',
  'deactivate',
  'toggle',
  'apply',
  'close',
]);

const placeholder = computed(() => {
  const dateFrom = searchStore.state.dateFrom;
  const dateTo = searchStore.state.dateTo;

  if (!isEmpty(dateFrom) && !isEmpty(dateTo)) {
    const formattedDates = model.value?.date?.selectedDateRange.map((date) =>
      d(new Date(date), 'short')
    );
    return joinNonEmptyStrings(formattedDates, ' - ');
  } else {
    return t('components.pageheader.search.item-date.input-fake.placeholder');
  }
});

const isDateFilterSet = computed(
  () =>
    !isEmpty([
      searchStore.state.dateFrom,
      searchStore.state.dateTo,
      searchStore.state.daytime,
    ])
);

const props = defineProps<{
  itemId: string;
  activeItem: string;
}>();

const value = ref<string>('');
const showDropdown = computed(() => {
  return props.activeItem === props.itemId;
});

const handleClick = () => {
  if (showDropdown.value) {
    emit('close');
  } else {
    emit('toggle', props.itemId);
  }
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
