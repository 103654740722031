<template>
  <div class="footer">
    <ButtonCTA
      :content="{
        text: $t('components.input.calendar.footer.button.text'),
        icon: 'ion:arrow-forward',
      }"
      @click="emit('apply')"
    />
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['apply']);
</script>

<style src="./Footer.scss" scoped lang="scss"></style>
