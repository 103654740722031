<template>
  <nav class="metaNav" aria-label="Meta-Navigation">
    <PageFooterContactMetaNavList
      :content="{ items: items, languageOptions: languageOptions }"
    />

    <PageFooterContactMetaNavLogo />
  </nav>
</template>

<script lang="ts" setup>
import type { LanguageOption, MetaNavItem } from '../../models';

const props = defineProps<{
  content: {
    items: MetaNavItem[];
    languageOptions: LanguageOption[];
  };
}>();

const items = toRef(() => props.content.items);
const languageOptions = toRef(() => props.content.languageOptions);
</script>

<style src="./MetaNav.scss" scoped lang="scss"></style>
