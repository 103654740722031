<template>
  <div class="global-grid">
    <div class="wrap">
      <ArticleTaglist :content="content" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Taglist } from './models';

const props = defineProps<{
  content: Taglist;
  navigationLink?: string;
}>();

const content = computed(() => props.content);
</script>

<style scoped lang="scss">
.wrap {
  grid-column: 1 / span 12;
}
</style>
