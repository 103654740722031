<template>
  <ul class="functions">
    <li class="item">
      <BaseWhlLink class="link" to="/bookmarks">
        <BaseIcon class="icon" name="ion:heart-outline" />
        <span class="text">{{
          t('components.pageheader.functions.bookmark')
        }}</span>
      </BaseWhlLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>
const { t } = useI18n();
</script>

<style src="./Functions.scss" scoped lang="scss"></style>
