<template>
  <li class="item">
    <button class="button" type="button" @click="handleClick">
      <ButtonBase :content="{ text: buttonText, icon: '' }" />
    </button>
  </li>
</template>

<script lang="ts" setup>
import type { TaglistItem } from '../models';
const searchStore = useSearchStore();
const widgetConfig = await useWidgetConfig();

const props = defineProps<{
  content: TaglistItem;
  navigationLink?: string;
}>();

const text = computed(() => props.content.text);
const count = computed(() => props.content.count);

const buttonText = computed(() => {
  return count.value ? `${text.value} (${count.value})` : text.value;
});

const handleClick = () => {
  searchStore.state.categories.push(props.content.id);
  searchStore.pushStateToRoute(widgetConfig);
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
