<template>
  <label class="input-checkbox" :class="{ error: error }">
    <input
      v-model="model"
      class="input"
      type="checkbox"
      :name="name"
      :value="value"
      :disabled="disabled"
    />

    <BaseIcon class="icon" name="ion:checkmark" />

    <span class="label" v-html="label" />
  </label>
</template>

<script lang="ts" setup>
const model = defineModel<number[]>();

defineProps<{
  label?: string;
  name?: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
}>();
</script>

<style src="./Checkbox.scss" scoped lang="scss"></style>
