<template>
  <div class="additional">
    <InputCalendarAdditionalDayTime
      v-model="model.dayTime"
      label="Tageszeit"
      :items="dayTime"
      name="dayTime"
    />

    <InputCalendarAdditionalCategories
      v-model="model.categories"
      label="Besonderheiten"
      :items="categories"
      name="categories"
    />
  </div>
</template>

<script lang="ts" setup>
const model = defineModel({ default: { dayTime: [], categories: [] } });

const dayTime = [
  { label: '6-12 Uhr', icon: 'mdi:sunrise', value: 'morning' },
  {
    label: '12-18 Uhr',
    icon: 'material-symbols:clear-day',
    value: 'afternoon',
  },
  { label: '18-24 Uhr', icon: 'mdi:weather-sunset-down', value: 'evening' },
];

const categories = [
  { label: 'nur einmalige Events anzeigen', value: 'single' },
  { label: 'nur Events mit freiem Eintritt', value: 'free-of-charge' },
  { label: 'Veranstaltungstipps zuerst anzeigen', value: 'recommendations' },
];
</script>

<style src="./Additional.scss" scoped lang="scss"></style>
