<template>
  <ul class="list">
    <PageheaderSearchItemDropdownFilterDistanceAutoSuggestListItem
      v-for="item in items"
      :content="item"
      @select="
        emit('select', { id: item.id, type: item.type, label: item.label })
      "
    />
  </ul>
</template>

<script lang="ts" setup>
import type { LocationItem } from '../../models';

const emit = defineEmits(['select']);

const props = defineProps<{
  content?: { items: LocationItem[] };
}>();

const items = toRef(() => props.content?.items);
</script>

<style src="./List.scss" scoped lang="scss"></style>
