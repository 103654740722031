<template>
  <div class="item">
    <button
      ref="button"
      type="button"
      class="button"
      @click="emit('toggle', itemId)"
    >
      <ButtonGhost :content="{ text: 'Filter', icon: 'ion:funnel-outline' }" />
    </button>

    <PageheaderSearchItemTab
      ref="tab"
      :content="{ icon: 'ion:funnel-outline', text: 'Filter' }"
      :value="searchStore.getNumberOfFilterItems(whlModuleType)"
      :active="showDropdown"
      @click="handleClick"
    />

    <PageheaderSearchItemDropdown
      v-if="showDropdown"
      v-focus-first
      v-click-outside="{
        ignore: [button, tab],
        handler: () => emit('deactivate', itemId),
      }"
      @keyup.esc="emit('deactivate', itemId)"
      @deactivate="emit('deactivate', itemId)"
    >
      <PageheaderSearchItemDropdownFilterEvent
        v-if="whlModuleType === WhlModuleType.Event"
        v-model="model"
        @apply="emit('apply', itemId)"
      />

      <PageheaderSearchItemDropdownFilterTour
        v-if="whlModuleType === WhlModuleType.Tour"
        v-model="model"
        @apply="emit('apply', itemId)"
      />

      <PageheaderSearchItemDropdownFilterPoi
        v-if="whlModuleType === WhlModuleType.Poi"
        v-model="model"
        v-model:date="date"
        @apply="emit('apply', itemId)"
      />
    </PageheaderSearchItemDropdown>
  </div>
</template>

<script lang="ts" setup>
import { WhlModuleType } from '../../../../models/WhlModuleType';
import type { DateFilter, FilterModel } from '../models';
const searchStore = useSearchStore();

const button = ref<HTMLElement | null>(null);
const tab = ref<HTMLElement | null>(null);

const emit = defineEmits([
  'activate',
  'deactivate',
  'toggle',
  'apply',
  'close',
]);

const model = defineModel<FilterModel>();
const date = defineModel<DateFilter>('date');

const props = defineProps<{
  itemId: string;
  activeItem: string;
  whlModuleType: WhlModuleType;
}>();

const showDropdown = computed(() => {
  return props.activeItem === props.itemId;
});

const handleClick = () => {
  if (showDropdown.value) {
    emit('close');
  } else {
    emit('activate', props.itemId);
  }
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
