<template>
  <li class="item">
    <button type="button" class="button" @click="emit('select')">
      {{ content.label }}
    </button>

    <span class="label">{{ determineTypeLabel(content.type) }}</span>
  </li>
</template>

<script lang="ts" setup>
import type { Nullable } from '../../../../../../../../../../models/CustomUtilityTypes';
import type { LocationItem, LocationType } from '../../../models';
const { t } = useI18n();

const emit = defineEmits(['select']);

function determineTypeLabel(type: Nullable<LocationType>): string {
  switch (type) {
    case 'AddressPoiGroup':
      return t(
        'components.pageheader.search.item.dropdown.filter.distance.autosuggest.list.item.location-type.address-poi-group'
      );
    case 'Location':
      return t(
        'components.pageheader.search.item.dropdown.filter.distance.autosuggest.list.item.location-type.location'
      );
    case 'Region':
      return t(
        'components.pageheader.search.item.dropdown.filter.distance.autosuggest.list.item.location-type.region'
      );
    default:
      return '';
  }
}

defineProps<{
  content: LocationItem;
}>();
</script>

<style src="./Item.scss" scoped lang="scss"></style>
