<template>
  <div class="item">
    <InputText
      ref="button"
      v-model="model"
      :label="t('common.searchfor')"
      :placeholder="placeholder"
      icon="ion:search"
    />

    <PageheaderSearchItemTab
      ref="tab"
      :content="{ icon: 'ion:search', text: t('common.search') }"
      :active="showDropdown"
      @click="handleClick"
    />

    <PageheaderSearchItemDropdown
      v-if="showDropdown"
      v-click-outside="{
        ignore: [button, tab],
        handler: () => emit('deactivate', itemId),
      }"
    >
      <PageheaderSearchItemDropdownTerm v-model="model" />
    </PageheaderSearchItemDropdown>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();

const model = defineModel<string>({
  default: '',
});

const emit = defineEmits(['activate', 'close', 'deactivate']);

const props = defineProps<{
  itemId: string;
  activeItem: string;
  placeholder: string;
}>();

const button = ref<HTMLElement | null>(null);
const tab = ref<HTMLElement | null>(null);

const showDropdown = computed(() => {
  return props.activeItem === props.itemId;
});

const handleClick = () => {
  if (showDropdown.value) {
    emit('close');
  } else {
    emit('activate', props.itemId);
  }
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
