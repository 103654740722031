<template>
  <div class="quick">
    <button
      class="button"
      :class="{ selected: selected === 0 }"
      @click="emit('setDate', [new Date()])"
    >
      {{ t('components.input.calendar.quick.today') }}
    </button>
    <button
      class="button"
      :class="{ selected: selected === 1 }"
      @click="emit('setDate', [getTomorrowDate()])"
    >
      {{ t('components.input.calendar.quick.tomorrow') }}
    </button>
    <button
      class="button"
      :class="{ selected: selected === 2 }"
      @click="emit('setDate', getNextWeekend())"
    >
      {{ t('components.input.calendar.quick.weekend') }}
    </button>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();

defineProps<{
  selected?: 0 | 1 | 2;
}>();

const emit = defineEmits(['setDate']);

const getNextWeekend = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();

  const daysUntilSaturday = (6 - dayOfWeek + 7) % 7 || 7;
  const nextSaturday = new Date(today);
  nextSaturday.setDate(today.getDate() + daysUntilSaturday);

  const nextSunday = new Date(nextSaturday);
  nextSunday.setDate(nextSaturday.getDate() + 1);

  return [nextSaturday, nextSunday];
};

const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};
</script>

<style src="./Quick.scss" scoped lang="scss"></style>
