<template>
  <li>
    <NuxtLink
      :to="switchLocalePath(content.value)"
      class="item"
      :aria-label="content.label"
      :class="{ active: active }"
    >
      <BaseIcon class="icon" :name="content.icon" />

      <small class="text">
        {{ content.label }}
      </small>
    </NuxtLink>
  </li>
</template>

<script lang="ts" setup>
const switchLocalePath = useSwitchLocalePath();
const { locale } = useI18n();

const props = defineProps<{
  content: {
    label: string;
    value: string;
    icon: string;
  };
}>();

const active = computed(() => locale.value === props.content.value);
</script>

<style src="./Item.scss" scoped lang="scss"></style>
