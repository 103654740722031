<template>
  <li class="item" :class="[{ active: active }, { small: small }]">
    <ArticleAccordionItemTitle
      :text="text"
      :active="active"
      :small="small"
      @click="toggle"
    />

    <div class="item__content" :class="{ active: active }">
      <div class="item__content__inner" :class="{ active: active }">
        <slot />
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    text: string;
    small?: boolean;
  }>(),
  {
    small: false,
  }
);

const active = ref(false);

const toggle = () => {
  active.value = !active.value;
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
