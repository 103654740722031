<template>
  <div class="list">
    <span v-if="content.title" class="title">{{ content.title }}</span>

    <ul class="items">
      <PageheaderSearchItemDropdownTermListItem
        v-for="item in content.list"
        :content="item"
        @click="emit('input', item.text)"
      />
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { List } from './models';

const emit = defineEmits(['input']);

defineProps<{
  content: List;
}>();
</script>

<style src="./List.scss" scoped lang="scss"></style>
