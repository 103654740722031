<template>
  <div class="taglist">
    <TextStyler v-if="title">
      <h3>{{ title }}</h3>
    </TextStyler>

    <ul class="list">
      <ArticleTaglistItem v-for="item in items" :content="item" />
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { Taglist } from './models';

const props = defineProps<{
  content: Taglist;
}>();

const title = computed(() => props.content.title);
const items = computed(() => props.content.items);
</script>

<style src="./Taglist.scss" scoped lang="scss"></style>
