<template>
  <div class="calendar">
    <InputCalendarDatePicker
      v-model="model.date.selectedDateRange"
      v-model:pending-date="model.date.pendingDate"
    />

    <InputCalendarAdditional v-model="model.additional" />

    <InputCalendarFooter @apply="emit('apply')" />
  </div>
</template>

<script lang="ts" setup>
import type { DateFilter } from '../../Pageheader/Search/models';

const model = defineModel<DateFilter>({
  default: {
    date: {
      selectedDateRange: [],
      pendingDate: '',
    },
    additional: {
      categories: [],
      dayTime: [],
    },
  } satisfies DateFilter,
});

const emit = defineEmits(['apply']);
</script>

<style src="./Calendar.scss" scoped lang="scss"></style>
