<template>
  <div class="title">
    <TextStyler v-if="title">
      <h2 class="text">{{ title }}</h2>
    </TextStyler>
  </div>
</template>

<script lang="ts" setup>
import type { Text } from '../models';

const props = defineProps<{
  content: Text;
}>();

const title = toRef(() => props.content.title);
</script>

<style src="./Title.scss" scoped lang="scss"></style>
