<template>
  <address class="address">
    <div class="heading">
      <span v-if="address.name">{{ address.name }}</span>
      <span v-if="address.street">
        {{ address.street }}
      </span>
      <span v-if="address.zipcode || address.city">
        {{ address.zipcode }} {{ address.city }}
      </span>
    </div>

    <a :href="`mailto:${address.email}`">{{ address.email }}</a>
  </address>
</template>

<script lang="ts" setup>
import type { Address } from '../../models';

const props = defineProps<{
  content: Address;
}>();

const address = toRef(() => props.content);
</script>

<style src="./Address.scss" scoped lang="scss"></style>
