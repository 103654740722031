<template>
  <div class="slider">
    <span
      class="range"
      :style="{
        left: `${100 * calcProportion(minModel)}%`,
        width: `${100 * (calcProportion(maxModel) - calcProportion(minModel))}%`,
      }"
    />
    <span
      class="thumb min"
      :style="{ left: `${100 * calcProportion(minModel)}%` }"
    />
    <span
      class="thumb max"
      :style="{ left: `${100 * calcProportion(maxModel)}%` }"
    />
    <input
      v-model.number="minModel"
      class="input min"
      type="range"
      tabindex="-1"
      :min="min"
      :max="max"
      step="1"
    />
    <input
      v-model.number="maxModel"
      class="input max"
      type="range"
      tabindex="-1"
      :min="min"
      :max="max"
      step="1"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  label?: string;
  min: number;
  max: number;
}>();

const minModel = defineModel<number>('minModel', { required: true });
const maxModel = defineModel<number>('maxModel', { required: true });

const calcProportion = (value: number) => {
  const max = props.max;
  const range = max - props.min;

  const correctedValue = value - props.min;
  return correctedValue / range;
};

watch(minModel, (newVal: number) => {
  const maxValue = maxModel.value;

  if (newVal >= maxValue) {
    minModel.value = maxValue - 1;
  }
  if (minModel.value < props.min) {
    minModel.value = props.min;
  }
});

watch(maxModel, (newVal: number) => {
  const minValue = minModel.value;

  if (newVal <= minValue) {
    maxModel.value = minValue + 1;
  }
  if (maxModel.value > props.max) {
    maxModel.value = props.max;
  }
});
</script>

<style src="./Slider.scss" scoped lang="scss"></style>
