<template>
  <div class="picker">
    <strong v-if="label" class="heading">{{ label }}</strong>

    <div class="list">
      <InputPickerItem
        v-for="item in items"
        :key="item"
        v-model="model"
        :label="item.label"
        :icon="item.icon"
        :value="item.value"
        :name="name"
        :disabled="disabled"
        type="checkbox"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const model = defineModel<number[]>({ default: [] });

defineProps<{
  label?: string;
  name?: string;
  disabled?: boolean;
  items?: Array<{ label: string; value: number }>;
}>();
</script>

<style src="./Picker.scss" scoped lang="scss"></style>
