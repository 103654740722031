import { useQuery } from '@urql/vue';
import type { AddressbaseFilter } from '../gql/schema';
import {
  AddressbaseCategoriesFacetsDocument,
  type AddressbaseCategoriesFacetsQuery,
  type AddressbaseCategoriesFacetsQueryVariables,
} from '../gql/queries/__generated/AddressbaseCategoriesFacets';

export default async (
  baseFilter: Ref<AddressbaseFilter>,
  userFilter: Ref<AddressbaseFilter>
) => {
  const filter = computed(() => {
    const userProductlines = userFilter.value?.productlines?.oneOf;
    const baseProductlines = baseFilter.value?.productlines;
    const mergedProductlines = {
      oneOf: [...(baseProductlines?.oneOf || [])],
      allOf: [...(baseProductlines?.allOf || [])],
      noneOf: [
        ...(baseProductlines?.noneOf || []),
        ...(userProductlines || []),
      ],
    };

    return {
      ...baseFilter.value,
      ...userFilter.value,
      productlines: !isEmpty(mergedProductlines)
        ? { ...mergedProductlines }
        : undefined,
    };
  });

  const { fetching, data, error } = await useQuery<
    AddressbaseCategoriesFacetsQuery,
    AddressbaseCategoriesFacetsQueryVariables
  >({
    query: AddressbaseCategoriesFacetsDocument,
    variables: {
      filter: filter,
    },
  });

  const facets = computed(() => {
    return data.value?.pois?.facets.fields?.at(0)?.facets;
  });

  return { fetching, error, facets };
};
