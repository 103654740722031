<template>
  <footer class="pageFooter">
    <div class="grid">
      <PageFooterContact
        v-if="address || metaNav"
        :content="{
          address: address,
          metaNav: metaNav,
          languageOptions: languageOptions,
        }"
      />

      <PageFooterAddEvent
        v-if="content.addRecord"
        :content="content.addRecord"
      />
    </div>
  </footer>
</template>

<script lang="ts" setup>
import type { PageFooter } from './models';

const props = defineProps<{
  content: PageFooter;
}>();

const address = toRef(() => props.content.address);
const metaNav = toRef(() => props.content.metaNav);
const languageOptions = toRef(() => props.content.languageOptions);
</script>

<style src="./PageFooter.scss" scoped lang="scss"></style>
