<template>
  <div class="autoSuggest">
    <PageheaderSearchItemDropdownFilterDistanceAutoSuggestList
      v-if="!isEmpty(content?.items)"
      :content="content"
      @select="emit('select', $event)"
    />

    <strong v-else class="noResults">
      {{
        $t(
          'components.pageheader.search.item.dropdown.filter.distance.autosuggest.noresults'
        )
      }}
    </strong>
  </div>
</template>

<script lang="ts" setup>
import type { LocationItem } from '../models';

const emit = defineEmits(['select']);

defineProps<{
  content?: { items: LocationItem[] };
}>();
</script>

<style src="./AutoSuggest.scss" scoped lang="scss"></style>
