import { useQuery } from '@urql/vue';
import type { MaybeRef, Ref } from 'vue';
import type { RawAddressbaseFieldStatsResultFragment } from '../gql/fragments/__generated/RawAddressbaseFieldStatsResult';
import type { RawFloatFieldStatsResultValueFragment } from '../gql/fragments/__generated/RawFloatFieldStatsResultValue';
import type { RawIntFieldStatsResultValueFragment } from '../gql/fragments/__generated/RawIntFieldStatsResultValue';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import {
  TourStatsDocument,
  type TourStatsQuery,
  type TourStatsQueryVariables,
} from '../gql/queries/__generated/TourStats';
import type { Nullable } from '../models/CustomUtilityTypes';

const LOWER_RECORD_COUNT_THRESHOLD = 5;

export default async (
  widgetConfig: MaybeRef<Nullable<RawWidgetConfigFragment>>
) => {
  const { locale } = useI18n();
  const baseFilter = buildTourBaseFilter(widgetConfig);

  const { fetching, data, error, pause, resume } = await useQuery<
    TourStatsQuery,
    TourStatsQueryVariables
  >({
    query: TourStatsDocument,
    variables: {
      language: locale,
      appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      filter: toValue(baseFilter),
    },
  });

  const stats: Ref<RawAddressbaseFieldStatsResultFragment[]> = computed(() => {
    return data.value?.pois?.facets?.stats ?? [];
  });

  const durationStats = computed((): RawIntFieldStatsResultValueFragment => {
    const fieldStats = toValue(stats).find(
      (stat) => stat.identifier === 'duration'
    );
    if (fieldStats && fieldStats.count > LOWER_RECORD_COUNT_THRESHOLD) {
      return fieldStats.value ?? null;
    }
    return null;
  });

  const lengthStats = computed((): RawFloatFieldStatsResultValueFragment => {
    const fieldStats = toValue(stats).find(
      (stat) => stat.identifier === 'length'
    );
    if (fieldStats && fieldStats.count > LOWER_RECORD_COUNT_THRESHOLD) {
      return fieldStats.value ?? null;
    }
    return null;
  });

  return { fetching, error, stats, durationStats, lengthStats, pause, resume };
};
