<template>
  <div class="footer">
    <button class="button" type="button" @click="emit('apply')">
      <ButtonCTA
        :content="{
          text: content,
          icon: 'ion:arrow-forward',
        }"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['apply']);

defineProps<{
  content?: string;
}>();
</script>

<style src="./Footer.scss" scoped lang="scss"></style>
