<template>
  <ul class="list">
    <PageFooterLanguageSwitchListItem
      v-for="item in content.languageOptions"
      :content="item"
      @click="emit('localeSelected', item.value)"
    />
  </ul>
</template>

<script lang="ts" setup>
import type { LanguageOption } from '../../models';

defineProps<{
  content: {
    languageOptions: LanguageOption[];
  };
}>();

const emit = defineEmits(['localeSelected']);
</script>

<style src="./List.scss" scoped lang="scss"></style>
