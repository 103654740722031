import { useQuery } from '@urql/vue';
import {
  EventCategoriesFacetsDocument,
  type EventCategoriesFacetsQuery,
  type EventCategoriesFacetsQueryVariables,
} from '../gql/queries/__generated/EventCategoriesFacets';
import type { EventFilter } from '../gql/schema';
import type { Nullable } from '../models/CustomUtilityTypes';

export default async (
  baseFilter: Ref<EventFilter>,
  userFilter: Ref<Nullable<EventFilter>>
) => {
  const filter = computed(() => {
    const userCategories = userFilter.value?.categories?.oneOf;
    const baseCategories = baseFilter.value?.categories;
    const mergedCategories = {
      oneOf: [...(baseCategories?.oneOf || [])],
      allOf: [...(baseCategories?.allOf || [])],
      noneOf: [...(baseCategories?.noneOf || []), ...(userCategories || [])],
    };

    return {
      ...baseFilter.value,
      ...userFilter.value,
      categories: !isEmpty(mergedCategories)
        ? { ...mergedCategories }
        : undefined,
    };
  });

  const { fetching, data, error } = await useQuery<
    EventCategoriesFacetsQuery,
    EventCategoriesFacetsQueryVariables
  >({
    query: EventCategoriesFacetsDocument,
    variables: {
      filter: filter,
    },
  });

  const facets = computed(() => {
    return data.value?.events?.facets.fields?.at(0)?.facets;
  });

  return { fetching, error, facets };
};
