//const LEVENSHTEIN_WEIGHT = 0.2;

export function computeScore(inputA: string, inputB: string): number {
  if (inputA === inputB) return 1;

  let score = 0;

  if (inputA.startsWith(inputB) || inputB.startsWith(inputA)) {
    score += 1;
  }

  // Lower the weight for includes but apply penalty for distance from the start
  else if (inputA.includes(inputB)) {
    const position = inputA.indexOf(inputB);
    // Penalize based on how far the match is from the start
    const penalty = position / inputA.length; // Higher penalty for matches farther from start
    score += 0.5 - penalty; // Base score for includes, but reduce based on position
  }

  // Calculate similarity based on Levenshtein distance and apply a smaller weight
  /*   const similarityScore = levenshteinDistance(inputA, inputB);
  score +=
    (1 - similarityScore / Math.max(inputA.length, inputB.length)) *
    LEVENSHTEIN_WEIGHT; */

  return score;
}

export function levenshteinDistance(a: string, b: string): number {
  const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
    Array(b.length + 1).fill(i)
  );
  for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1)
      );
    }
  }

  return matrix[a.length][b.length];
}

export default function (input: string, label: string): number {
  return computeScore(
    input.trim().toLocaleLowerCase(),
    label.trim().toLocaleLowerCase()
  );
}
