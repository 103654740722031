<template>
  <label class="item" :for="name">
    <BaseIcon v-if="icon" class="icon" :name="icon" />

    {{ label }}

    <input
      v-model="model"
      class="input"
      type="checkbox"
      :name="name"
      :value="value"
      :disabled="disabled"
    />
  </label>
</template>

<script lang="ts" setup>
const model = defineModel();

defineProps<{
  label?: string;
  icon?: string;
  value?: string;
  name?: string;
  disabled?: boolean;
}>();
</script>

<style src="./Item.scss" scoped lang="scss"></style>
