<template>
  <li class="item">
    <a class="link" :href="href" :target="target">
      {{ text }}
    </a>
  </li>
</template>

<script lang="ts" setup>
import type { MetaNavItem } from '../../../../models';

const props = defineProps<{
  content: MetaNavItem;
}>();

const href = toRef(() => props.content.href);
const text = toRef(() => props.content.text);
const target = toRef(() => props.content.target);
</script>

<style src="./Item.scss" scoped lang="scss"></style>
