<template>
  <div
    v-click-outside="() => (open = false)"
    class="languageSwitch"
    :class="{ open: open }"
  >
    <button ref="button" type="button" class="value" @click="open = !open">
      <span class="text">{{ activeLanguage.label }}</span>

      <BaseIcon
        class="icon"
        name="material-symbols:keyboard-arrow-down-rounded"
      />
    </button>

    <PageFooterLanguageSwitchOverlay v-show="open">
      <PageFooterLanguageSwitchList
        v-model="activeLanguage"
        :content="{ languageOptions: content }"
        @locale-selected="open = false"
        @tab-back="focusButton"
      />
    </PageFooterLanguageSwitchOverlay>
  </div>
</template>

<script lang="ts" setup>
import type { LanguageOption } from '../models';
const { locale } = useI18n();

const button = ref<HTMLButtonElement | null>(null);

const open = ref<boolean>(false);

const focusButton = () => {
  if (button.value) {
    button.value.focus();
  }
};

const props = defineProps<{
  content: LanguageOption[];
}>();

const activeLanguage = computed(() => {
  return (
    props.content.find(
      (option: LanguageOption): boolean => option.value === locale.value
    ) ?? props.content[0]
  );
});
</script>

<style src="./LanguageSwitch.scss" scoped lang="scss"></style>
